const inhotelRouter = {
  path: '/inhotel',
  component: () => import(/* webpackChunkName: "inhotel-view-index" */ '../views/index.vue'),
  children: [
    // 工作台管理
    {
      path: 'workflow',
      name: 'workflow',
      meta: {
        permissionCode: 'cp-hotel-workFlow',
        routerType: 3,
      },
      component: () => import('../views/work-flow/work-flow.vue'),

    },
    // 优先选房
    {
      path: 'checkinList',
      name: 'checkinList',
      meta: {
        permissionCode: 'cp-hotel-firstSelectRoom',
        routerType: 3,
        h_authCode: '2',
      },
      component: () => import('../views/order-list/checkin-list.vue'),
    },
    {
      path: 'checkinHistory',
      name: 'checkinHistory',
      meta: {
        permissionCode: 'cp-hotel-firstSelectRoom',
        routerType: 3,
        h_authCode: '2',
      },
      component: () => import('../views/order-list/checkin-history.vue'),

    },

    // 客房服务配置

    {
      path: 'shopServiceHistory',
      name: 'shopServiceHistory',
      meta: {
        permissionCode: 'cp-hotel-roomService',
        routerType: 3,
        h_authCode: 'ZDFW',
      },
      component: () => import('../views/shop-service/shop-service-history.vue'),
    },
    {
      path: 'shopService',
      name: 'shopService',
      meta: {
        permissionCode: 'cp-hotel-roomService',
        routerType: 3,
        h_authCode: 'ZDFW',
      },
      component: () => import('../views/shop-service/shop-service.vue'),
    },
    {
      path: 'shopServiceComplain',
      name: 'shopServiceComplain',
      meta: {
        permissionCode: 'cp-hotel-roomService',
        routerType: 3,
        h_authCode: 'ZDFW',
      },
      component: () => import('../views/shop-service/shopServiceComplain.vue'),
    },
    {
      path: 'shopServiceBorrow',
      name: 'shopServiceBorrow',
      meta: {
        permissionCode: 'cp-hotel-roomService',
        routerType: 3,
        h_authCode: 'ZDFW',
      },
      component: () => import('../views/shop-service/shop-service-borrow.vue'),
    },
    {
      path: 'shopServiceOther',
      name: 'shopServiceOther',
      meta: {
        permissionCode: 'cp-hotel-roomService',
        routerType: 3,
        h_authCode: 'ZDFW',
      },
      component: () => import('../views/shop-service/shop-service-other.vue'),
    },
    {
      path: 'shopServiceRepair',
      name: 'shopServiceRepair',
      meta: {
        permissionCode: 'cp-hotel-roomService',
        routerType: 3,
        h_authCode: 'ZDFW',
      },
      component: () => import('../views/shop-service/shop-service-repair.vue'),
    },
    {
      path: 'checkoutRoom',
      name: 'checkoutRoom',
      meta: {
        permissionCode: 'cp-hotel-roomService',
        routerType: 3,
        h_authCode: 'ZDFW',
      },
      component: () => import('../views/shop-service/checkout-continued.vue'),

    },
    // 发票管理
    {
      path: 'invoiceManage',
      name: 'invoiceManage',
      meta: {
        permissionCode: 'cp-hotel-invoiceManage',
        routerType: 3,
      },
      component: () => import('../views/invoice-manage/invoice-management.vue'),
    },
    {
      path: 'invoiceConfigNew',
      name: 'invoiceConfigNew',
      meta: {
        permissionCode: 'cp-hotel-invoiceManage',
        routerType: 3,
      },
      component: () => import('../views/invoice-manage/invoice-config-new.vue'),

    },
    // 服务小费

    {
      path: 'tip',
      name: 'tip',
      meta: {
        permissionCode: 'cp-hotel-serviceTip',
        routerType: 3,
      },
      component: () => import('../views/service-pay/service-pay.vue'),

    },
    {
      path: 'invoiceSupplier',
      name: 'invoiceSupplier',
      meta: {
        permissionCode: 'cp-group-invoiceSupplier',
        routerType: 2,
        title: '发票供应商管理',
      },
      component: () => import('../views/invoice-supplier/invoice-supplier.vue'),
    },
    {
      path: 'blueToothDoor',
      name: 'blueToothDoor',
      meta: {
        permissionCode: 'cp-group-blueToothDoor',
        routerType: 2,
        title: '蓝牙门锁',
      },
      component: () => import('../views/blue-tooth-door/blue-tooth-door.vue'),
    },
    {
      path: 'netWorkCall',
      name: 'netWorkCall',
      meta: {
        permissionCode: 'cp-group-blueToothDoor',
        routerType: 2,
        title: '一键打电话',
      },
      component: () => import('../views/network-call/index.vue'),
    },
    {
      path: 'smartTV',
      name: 'smartTV',
      meta: {
        permissionCode: 'cp-group-blueToothDoor',
        routerType: 2,
        title: '智能电视',
      },
      component: () => import('../views/smart-tv/index.vue'),
    },
    {
      path: 'wifiPortal',
      name: 'wifiPortal',
      meta: {
        permissionCode: 'cp-group-blueToothDoor',
        routerType: 2,
        title: 'WiFi-Portal',
      },
      component: () => import('../views/wifi-portal/index.vue'),
    },
    {
      path: 'wifiPortalEdit',
      name: 'wifiPortalEdit',
      meta: {
        permissionCode: 'cp-group-blueToothDoor',
        routerType: 2,
        title: 'WiFi-Portal',
      },
      component: () => import('../views/wifi-portal/edit-wifi-portal.vue'),
    },
    {
      path: 'editAdInfo',
      name: 'editAdInfo',
      meta: {
        permissionCode: 'cp-group-blueToothDoor',
        routerType: 2
      },
      component: () => import('../views/smart-tv/child/edit-ad-info.vue'),
    },
    {
      path: 'smartSwitch',
      name: 'smartSwitch',
      meta: {
        permissionCode: 'cp-group-blueToothDoor',
        routerType: 2,
        title: '酒店智能化',
      },
      component: () => import('../views/smart-switch/index.vue'),
    },
    {
      path: 'editSmartSwitch',
      name: 'editSmartSwitch',
      meta: {
        permissionCode: 'cp-group-blueToothDoor',
        routerType: 2,
        title: '酒店智能化',
      },
      component: () => import('../views/smart-switch/edit-smart-switch.vue'),
    },
    // 服务评价


    {
      path: 'serviceEvaluate',
      name: 'serviceEvaluate',
      meta: {
        permissionCode: 'cp-group-serviceEvaluate',
        routerType: 3,
      },
      component: () => import('../views/hotel-service-evaluate/service-evaluate.vue'),
    },
    {
      path: 'evaluateDetail',
      name: 'evaluateDetail',
      meta: {
        permissionCode: 'cp-group-serviceEvaluate',
        routerType: 3,
      },
      component: () => import('../views/hotel-service-evaluate/evalute-detail.vue'),
    }, {
      path: 'rewardCollect',
      name: 'rewardCollect',
      meta: {
        permissionCode: 'cp-group-serviceEvaluate',
        routerType: 3,
      },
      component: () => import('../views/shop-service/reward-collect.vue'),
    },
    // 小度音箱

    {
      path: 'baiduVodiaBox',
      name: 'baiduVodiaBox',
      meta: {
        permissionCode: 'cp-baiduVodiaBox',
        routerType: 3,
      },
      component: () => import('../views/baidu-vodia-box/online-status.vue'),
    },
    // 集团服务评价

    {
      path: 'group/serviceEvaluate',
      name: 'group-serviceEvaluate',
      meta: {
        permissionCode: 'cp-group-serviceEvaluate',
        routerType: 2,
      },
      component: () => import('../views/group-service-evaluate/group-service-evaluate.vue'),
    },
    {
      path: 'group/questionBank',
      name: 'questionBank',
      meta: {
        permissionCode: 'cp-group-serviceEvaluate',
        routerType: 2,
      },
      component: () => import('../views/group-service-evaluate/question-bank.vue'),
    },
    {
      path: 'group/hotelEvaDetail',
      name: 'hotelEvaDetail',
      meta: {
        permissionCode: 'cp-group-serviceEvaluate',
        routerType: 2,
      },
      component: () => import('../views/group-service-evaluate/hotel-eva-detail.vue'),
    },
    {
      path: 'group/groupNPSdetail',
      name: 'groupNPSDetail',
      meta: {
        permissionCode: 'cp-group-serviceEvaluate',
        routerType: 2,
      },
      component: () => import('../views/group-service-evaluate/group-nps-detail.vue'),
    },
    {
      path: 'group/reviewLabel',
      name: 'reviewLabel',
      meta: {
        permissionCode: 'cp-group-serviceEvaluate',
        routerType: 2,
      },
      component: () => import('../views/group-service-evaluate/review-babel.vue'),
    },
    // Stay-用户点评
    {
      path: 'reviewList',
      name: 'reviewList',
      meta: {
        permissionCode: 'cp-group-stayReview',
        routerType: 2,
      },
      component: () => import('../views/review-manage/reviewList.vue'),
    },
    // Stay-酒店点评回复
    {
      path: 'replyList',
      name: 'replyList',
      meta: {
        permissionCode: 'cp-group-stayReview',
        routerType: 2,
      },
      component: () => import('../views/review-manage/reviewList.vue'),
    },
    // Stay-酒店点评回复
    {
      path: 'checkinGuide',
      name: 'checkinGuide',
      meta: {
        permissionCode: 'cp-group-checkinGuide',
        routerType: 2,
      },
      component: () => import('../views/checkin-guide/index.vue'),
    },
  ],
};

export default inhotelRouter;
